/**
 *
 * Loadable App components
 *
 */

import { lazyLoad } from 'utils/loadable';

export const GlobalConfig = lazyLoad(
  () => import('./GlobalConfig'),
  module => module.GlobalConfig,
);
