import { BaseXHR } from 'utils/axios';
import {
  I_PayloadUpdateVisitorAffiliate,
  I_OptionsGetListUserUseCoupon,
  I_OptionsGetListConversation,
  I_PayloadRequestPromoCoupon,
  I_OptionsGetListCommission,
  I_PayloadUpdatePromoCoupon,
  I_OptionsGetListReferral,
  I_OptionsGetListCoupon,
  I_OptionsGetStats,
} from 'app/containers/Profile/Affiliate/types';

export const updateVisitorAffiliateLinkApi = (
  data: I_PayloadUpdateVisitorAffiliate,
) => BaseXHR.$put('auth/affiliate-update-visitor', data);

export const getMyStatsAffiliateApi = (payload: I_OptionsGetStats) =>
  BaseXHR.$get('account/affiliate-dashboard', payload);

export const getListTransactionAffiliateUserApi = payload =>
  BaseXHR.$get('transaction/gets', payload);

export const getListCommissionAffiliateUserApi = (
  payload: I_OptionsGetListCommission,
) => BaseXHR.$get('account/affiliate-commision', payload);

export const getListConversationAffiliateUserApi = (
  payload: I_OptionsGetListConversation,
) => BaseXHR.$get('account/affiliate-conversations', payload);

export const getListUserUseCouponAffiliateApi = (
  payload: I_OptionsGetListUserUseCoupon,
) => BaseXHR.$get('account/affiliate-conversations', payload);

export const getListReferralAffiliateUserApi = (
  payload: I_OptionsGetListReferral,
) => BaseXHR.$get('account/affiliate-referal', payload);

export const changeEmailPaypalApi = (payload: { email: string }) =>
  BaseXHR.$put('account/paypal-email', payload);

export const getListCouponApi = (payload: I_OptionsGetListCoupon) =>
  BaseXHR.$get('coupon/gets', payload);

export const requestPromotionCouponApi = (
  payload: I_PayloadRequestPromoCoupon,
) => BaseXHR.$put('request-coupon/getPromo', payload);

export const updatePromotionCouponApi = (payload: I_PayloadUpdatePromoCoupon) =>
  BaseXHR.$put('request-coupon/updatePromo', payload);
