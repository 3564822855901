import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.editors || initialState;

export const selectEditors = createSelector(
  [selectDomain],
  editorsState => editorsState,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading !== 0,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectDetailProject = createSelector(
  [selectDomain],
  state => state.detailProject,
);

export const selectHotspotConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.hotspotConfig,
);

export const selectPolygonHotspotConfigGlobal = createSelector(
  [selectDetailProject],
  project => project?.config?.design?.polygonConfig,
);

export const selectTitleProjectSelected = createSelector(
  [selectDomain],
  state => state.detailProject?.title || '',
);

/**
 * GROUPS
 */

export const selectIsLoadingGroup = createSelector(
  [selectDomain],
  state => state.group.loading !== 0,
);

export const selectIsErrorGroup = createSelector(
  [selectDomain],
  state => state.group.error,
);

export const selectIsSuccessGroup = createSelector(
  [selectDomain],
  state => state.group.success,
);

export const selectListGroup = createSelector(
  [selectDomain],
  state => state.group.groups,
);

/**
 * ! SCENE
 */

export const selectIsLoadingScene = createSelector(
  [selectDomain],
  state => state.scene.loading !== 0,
);

export const selectIsErrorScene = createSelector(
  [selectDomain],
  state => state.scene.error,
);

export const selectIsSuccessScene = createSelector(
  [selectDomain],
  state => state.scene.success,
);

export const selectListScene = createSelector([selectDomain], state =>
  state.scene.listScene
    .filter(el => !el.parent)
    .map(item => ({
      ...item,
      staging:
        state.scene.listScene
          ?.filter(el => el.parent && el.parent === item.id)
          // Because the array is frozen in strict mode, you'll need to copy the array before sorting it: (https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326)
          ?.filter(el => el.mode_in_parent === 'staging')
          ?.slice()
          ?.sort((a, b) =>
            a.sort_in_parent === b.sort_in_parent
              ? Math.abs(
                  new Date(a.updated_at).valueOf() -
                    new Date(b.updated_at).valueOf(),
                )
              : a.sort_in_parent - b.sort_in_parent,
          ) || [],
      day2dust:
        state.scene.listScene
          ?.filter(el => el.parent && el.parent === item.id)
          ?.filter(el => el.mode_in_parent === 'day2dust') || [],
    })),
);
