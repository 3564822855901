import React, { memo } from 'react';
import { Switch, Router, Redirect } from 'react-router-dom';
import { Dashboard } from 'app/containers/Dashboard/Loadable';
import { Editors } from 'app/containers/Editors/Loadable';
import { Account } from 'app/containers/Account/Loadable';
import { Profile } from 'app/containers/Profile/Loadable';
import { MiddlewareToPreviewPage } from 'app/containers/MiddlewareToPreviewPage/Loadable';
import { MiddlewareToCollaborationPage } from 'app/containers/MiddlewareToCollaborationPage/Loadable';
import { Healthcheck } from 'app/containers/Healthcheck/Loadable';
import { Notfound } from 'app/containers/Notfound/Loadable';
import { PrivacyPolicies } from 'app/containers/PrivacyPolicies/Loadable';
import { RecommendUsePc } from 'app/containers/RecommendUsePc/Loadable';
import { AnalyticUsers } from 'app/containers/AnalyticUsers/Loadable';
import { Callback } from 'app/containers/Callback/Loadable';
import { ProtectedRoute, PublicRoute } from 'app/components/RouteGuard';
import { history } from 'utils/history';
import { GlobalConfig } from './';
import { MiddlewareToLandingPage } from 'app/containers/MiddlewareToLandingPage/Loadable';

export const RouterContainer = memo(() => {
  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute
          path={process.env.PUBLIC_URL + '/dashboard'}
          component={Dashboard}
        />
        <ProtectedRoute
          path={process.env.PUBLIC_URL + '/editor/:project_id'}
          component={Editors}
        />
        <ProtectedRoute
          path={process.env.PUBLIC_URL + '/profile'}
          component={Profile}
        />
        <ProtectedRoute
          path={
            process.env.PUBLIC_URL + '/redirect/preview/:project_id/:scene_id'
          }
          component={MiddlewareToPreviewPage}
        />
        <ProtectedRoute
          path={process.env.PUBLIC_URL + '/redirect/collaboration/:project_id'}
          component={MiddlewareToCollaborationPage}
        />
        <ProtectedRoute
          path={process.env.PUBLIC_URL + '/callback-panoee'}
          component={MiddlewareToLandingPage}
        />
        <ProtectedRoute
          path={process.env.PUBLIC_URL + '/callback'}
          component={Callback}
        />
        <PublicRoute
          path={process.env.PUBLIC_URL + '/account'}
          component={Account}
        />
        <PublicRoute
          path={process.env.PUBLIC_URL + '/healthcheck'}
          component={Healthcheck}
        />

        <ProtectedRoute path="/analytics" component={AnalyticUsers} />

        <ProtectedRoute path="/recommend-use-pc" component={RecommendUsePc} />

        <Redirect exact from={`/`} to={`/dashboard`} />

        <PublicRoute path="/404" component={Notfound} />

        <PublicRoute path="/policies" component={PrivacyPolicies} />

        <PublicRoute component={Notfound} />
      </Switch>

      <GlobalConfig />
    </Router>
  );
});

export default RouterContainer;
