import { BaseXHR } from 'utils/axios';

export const callApiLogin = payload => BaseXHR.$post('auth/login', payload);

export const callApiLoginSocial = payload =>
  BaseXHR.$post('auth/login-social', payload);

export const callApiRegister = payload =>
  BaseXHR.$post('auth/register', payload);

export const callApiRegisterFb = payload =>
  BaseXHR.$post('auth/register-fb', payload);

export const callApiRegisterGg = payload =>
  BaseXHR.$post('auth/register-gg', payload);

export const callApiVerifyAccount = payload =>
  BaseXHR.$put('auth/active-code', payload);

export const callApiForgotPassword = payload =>
  BaseXHR.$put('auth/forgot-password', payload);

export const callApiResetPassword = payload =>
  BaseXHR.$put('auth/change-forgot-password', payload);

export const callApiGetProfile = () => BaseXHR.$get('account/profile');

export const callApiLogout = payload => BaseXHR.$get('account/logout', payload);

export const getAWSKeyApi = () => BaseXHR.$get('account/aws');

export const generateTokenForLandingPageApi = () =>
  BaseXHR.$get('account/generate-token-landingpage');

export const initFreeTrialApi = () => BaseXHR.$get('account/init-free-trial');

export const getStatsApi = () => BaseXHR.$get('account/get-stats-account');

export const getSubscriptionApi = payload =>
  BaseXHR.$get('account/subscription', payload);

export const deactivateAccountApi = (payload: {
  reason: string;
  describe: string;
}) => BaseXHR.$put('account/deactivate', payload);
