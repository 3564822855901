// import { $get } from 'utils/axios';
import {
  take,
  select,
  fork,
  takeLatest,
  takeEvery,
  all,
  call,
  put,
} from 'redux-saga/effects';
import {
  callApiGetListMedia,
  deletesOrMovesMediaApi,
  getListMediaBuiltInApi,
  uploadMedia,
} from 'app/services/mediaApi';
import {
  actions,
  DELETES_OR_MOVES_MEDIA,
  GET_AWS,
  GET_LIST_ALBUM,
  GET_LIST_CATEGORY,
  GET_LIST_MEDIA,
  GET_LIST_MEDIA_BUILT_IN,
  GET_LIST_NOTIFICATION,
  GET_LIST_LOCATION,
  GET_LIST_PROVINCE,
  GET_LIST_TRANSLATION,
  GET_ME,
  GET_SUBSCRIPTION,
  LOGOUT,
  UPLOAD_MEDIA,
  GET_LIST_PLACE_CATEGORY,
} from './slice';
import {
  selectInfoUser,
  selectTotalDocMedia,
  selectTotalPageMedia,
} from './selectors';
import { LOGIN } from '../Account/slice';
import {
  callApiGetProfile,
  callApiLogout,
  getAWSKeyApi,
  getSubscriptionApi,
  initFreeTrialApi,
} from 'app/services/authenApi';
import { history } from 'utils/history';
import { store } from 'index';
import { getCookie, removeCookie } from 'utils/cookies';
import { TYPE_COOKIE } from 'utils/constants';
import { getListCategoryApi } from 'app/services/categoryApi';
import { getListNotificationApi } from 'app/services/notificationApi';
import {
  getListLocationApi,
  getListPlaceApi,
  getListProvinceApi,
  getListTranslationApi,
} from 'app/services/pimCoreServiceApi';

export function* watchAndLog() {
  while (true) {
    const styleConsole =
      'background: yellow; font-weight: bold; color: #40a9ff8c;';
    const action = yield take('*');
    const state = yield select();
    if (
      action.type !== '@@router/LOCATION_CHANGE' &&
      action.type !== 'app/updateMediaUploadingProgress' &&
      action.type !== 'reapop/upsertNotification' &&
      action.type !== 'reapop/dismissNotification'
    ) {
      console.group(action.type);
      console.log('%cBefore State', styleConsole, state);
      console.info('%cDispatching', styleConsole, action);
      console.log('%cNext State', styleConsole, state);
      console.groupEnd();
    }
  }
}

function* uploadImage({ payload }) {
  try {
    const response = yield call(uploadMedia, payload);
    store.dispatch(
      actions.updateMediaUploadingProgress({
        id: payload.idTemp,
        progress: 100,
        folder: payload.folder,
      }),
    );
    const { data } = response;
    yield put(actions.removeMediaUploadingProgress(payload.idTemp));
    yield put(
      UPLOAD_MEDIA.success({
        ...data.data,
        folder: payload.folder || '',
      }),
    );
  } catch (err) {
    yield put(actions.removeMediaUploadingProgress(payload.idTemp));
    yield put(UPLOAD_MEDIA.failure());
  }
}

function* getListMedia({ payload }) {
  try {
    const totalPages = yield select(selectTotalPageMedia);
    const totalDocs = yield select(selectTotalDocMedia);
    const response = yield call(callApiGetListMedia, payload);
    yield put(GET_LIST_MEDIA.success(response.data.data.docs));
    if (
      totalPages !== response.data.data.totalPages ||
      totalDocs !== response.data.data.totalDocs
    )
      yield put(
        actions.setOptionPaginationMedia({
          totalPageMedia: response.data.data.totalPages,
          totalDocMedia: response.data.data.totalDocs,
        }),
      );
  } catch (err) {
    yield put(GET_LIST_MEDIA.failure());
  }
}

function* deletesOrMovesMedia({ payload }) {
  try {
    const { data } = yield call(deletesOrMovesMediaApi, payload);
    if (data?.msg)
      yield put(
        DELETES_OR_MOVES_MEDIA.success({
          ids: payload.ids,
          type: payload.type,
        }),
      );
  } catch (err) {
    yield put(DELETES_OR_MOVES_MEDIA.failure());
  }
}

function* getListMediaBuiltIn() {
  try {
    const payload = { page: 1, limit: 10000 };
    const [
      { data: hotspotImages },
      { data: hotspotLotties },
      { data: hotspotMarkers },
    ] = yield all([
      call(getListMediaBuiltInApi, {
        ...payload,
        type: 'hotspot-image',
      }),
      call(getListMediaBuiltInApi, {
        ...payload,
        type: 'hotspot-lottie',
      }),
      call(getListMediaBuiltInApi, {
        ...payload,
        type: 'hotspot-marker',
      }),
    ]);

    yield put(
      GET_LIST_MEDIA_BUILT_IN.success({
        hotspotImages: hotspotImages?.data?.docs || [],
        hotspotLotties: hotspotLotties?.data?.docs || [],
        hotspotMarkers: hotspotMarkers?.data?.docs || [],
      }),
    );
  } catch (err) {
    yield put(GET_LIST_MEDIA_BUILT_IN.failure());
  }
}

function* getListAlbum({ payload }) {
  try {
    const response = yield call(callApiGetListMedia, payload);
    yield put(GET_LIST_ALBUM.success(response.data.data.docs));
  } catch (err) {
    yield put(GET_LIST_ALBUM.failure());
  }
}

function* getListCategory() {
  try {
    const response = yield call(getListCategoryApi);
    yield put(GET_LIST_CATEGORY.success(response.data.data.docs));
  } catch (err) {
    yield put(GET_LIST_CATEGORY.failure());
  }
}

export function* getAwsConfig() {
  try {
    const response = yield call(getAWSKeyApi);
    const data = { ...response.data.data };
    yield put(GET_AWS.success(data));
  } catch {
    yield put(GET_AWS.failure());
  }
}

function* watchIsLogged() {
  while (true) {
    yield take(LOGIN.SUCCESS);
    yield put(actions.changeIsLogged({ isLogged: true }));
  }
}

export function* getSubscription() {
  try {
    const affiliateId = yield getCookie(TYPE_COOKIE.AFFILIATE_ID);
    const device_id = yield getCookie(TYPE_COOKIE.DEVICE_ID) || undefined;
    const response = yield call(getSubscriptionApi, {
      affiliateId,
      device_id,
    });
    if (affiliateId) yield removeCookie(TYPE_COOKIE.AFFILIATE_ID);
    yield put(GET_SUBSCRIPTION.success(response.data));
  } catch {
    yield put(GET_SUBSCRIPTION.failure());
  }
}

export function* getTranslations() {
  try {
    const response = yield call(getListTranslationApi);
    console.log(response);
    const data = { ...response.data };
    yield put(GET_LIST_TRANSLATION.success(data));
  } catch {
    yield put(GET_LIST_TRANSLATION.failure());
  }
}

export function* getPlaces({ payload = {} } = {}) {
  try {
    const response = yield call(getListPlaceApi, payload);
    yield put(GET_LIST_PLACE_CATEGORY.success(response.data.data));
  } catch {
    yield put(GET_LIST_PLACE_CATEGORY.failure());
  }
}

export function* getLocations({ payload = {} } = {}) {
  try {
    const response = yield call(getListLocationApi, payload);
    yield put(GET_LIST_LOCATION.success(response.data.data));
  } catch {
    yield put(GET_LIST_LOCATION.failure());
  }
}

export function* getProvinces({ payload = {} } = {}) {
  try {
    const response = yield call(getListProvinceApi, payload);
    yield put(GET_LIST_PROVINCE.success(response.data.data));
  } catch {
    yield put(GET_LIST_PROVINCE.failure());
  }
}

export function* getMe({ payload }: any) {
  try {
    const infoUser = yield select(selectInfoUser);
    if (!infoUser) yield call(initFreeTrialApi);
    const response = yield call(callApiGetProfile);
    yield put(GET_ME.success(response.data));
    yield call(getPlaces);
    yield call(getProvinces);
    yield call(getLocations);
    yield call(getTranslations);
    yield call(getSubscription);
  } catch {
    yield put(GET_ME.failure());
  }
}

function* getListNotification({ payload }: any) {
  try {
    const infoUser = yield select(selectInfoUser);
    if (infoUser) {
      const { data } = yield call(getListNotificationApi, {
        ...payload,
        search: infoUser?.email || '',
      });
      yield put(GET_LIST_NOTIFICATION.success(data.data.docs || []));
    }
  } catch {
    yield put(GET_LIST_NOTIFICATION.failure());
  }
}

function* logout({ payload }: any) {
  try {
    const device_id = yield getCookie(TYPE_COOKIE.DEVICE_ID) || undefined;
    yield call(callApiLogout, {
      device_id,
    });
  } catch {
    yield put(LOGOUT.failure());
  } finally {
    yield put(LOGOUT.success());
    yield put(actions.changeIsLogged({ isLogged: false }));
    yield put(actions.resetMedia());
    yield history.push('/account/auth');
  }
}

export function* appSaga() {
  yield fork(watchIsLogged);
  yield takeLatest(GET_ME.TRIGGER, getMe);
  yield takeLatest(LOGOUT.TRIGGER, logout);
  yield takeLatest(GET_AWS.TRIGGER, getAwsConfig);
  yield takeLatest(GET_LIST_ALBUM.TRIGGER, getListAlbum);
  yield takeLatest(GET_SUBSCRIPTION.TRIGGER, getSubscription);
  yield takeLatest(GET_LIST_CATEGORY.TRIGGER, getListCategory);
  yield takeLatest(GET_LIST_NOTIFICATION.TRIGGER, getListNotification);
  yield takeLatest(DELETES_OR_MOVES_MEDIA.TRIGGER, deletesOrMovesMedia);
  yield takeLatest(GET_LIST_MEDIA_BUILT_IN.TRIGGER, getListMediaBuiltIn);
  yield takeEvery(GET_LIST_MEDIA.TRIGGER, getListMedia);
  yield all([takeEvery(UPLOAD_MEDIA.TRIGGER, uploadImage)]);
  if (process.env.NODE_ENV === 'development') yield fork(watchAndLog);
}
