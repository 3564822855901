import { BaseXHR } from 'utils/axios';

export const getListCardApi = () => BaseXHR.$get('stripe/gets');

export const createCardApi = payload => BaseXHR.$post('stripe/create', payload);

export const setDefaultCardApi = payload =>
  BaseXHR.$put('stripe/delfaultCard', payload);

export const deleteCardApi = payload =>
  BaseXHR.$delete('stripe/delete', payload);
