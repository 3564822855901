/**
 *
 * Asynchronously loads the component for MiddlewareToPreviewPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MiddlewareToPreviewPage = lazyLoad(
  () => import('./index'),
  module => module.MiddlewareToPreviewPage,
);
