import { createRoutine } from 'redux-saga-routines';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  TypeProject,
  I_Project,
  I_PayloadCreateProject,
} from './types';
import { I_OptionDelete, I_OptionsGetList } from 'types';

// The initial state of the ProjectManagement container
export const initialState: ContainerState = {
  listProject: [],
  listAllProject: [],
  statusFilterProject: 'all',
  totalPage: 0,
  error: false,
  loading: false,
  success: false,
  optionsGetList: {
    limit: 40,
    page: 1,
    search: '',
    order: 'desc',
    order_by: 'updated_at',
  },
};

export const GET_LIST_ALL_PROJECT = createRoutine(
  'dashboard/getListAllProject',
);
export const GET_LIST_PROJECT = createRoutine('dashboard/getListProject');
export const CREATE_PROJECT = createRoutine('dashboard/createProject');
export const UPDATE_PROJECT = createRoutine('dashboard/updateProject');
export const DELETE_PROJECT = createRoutine('dashboard/deleteProject');

const projectManagementSlice = createSlice({
  name: 'projectManagement',
  initialState,
  reducers: {
    updateProject(state, action: PayloadAction<I_Project>) {
      state.listProject.length &&
        (state.listProject = state.listProject.map(item =>
          item.id === action.payload.id ? action.payload : item,
        ));
    },

    switchCategory(state, action: PayloadAction<TypeProject>) {
      state.totalPage = 0;
      state.statusFilterProject = action.payload;
    },
    /**
     * !SET total page & total project
     *  */
    setOptionPagination(
      state,
      action: PayloadAction<Partial<{ totalPage: number }>>,
    ) {
      const { totalPage } = action.payload;
      totalPage !== undefined &&
        (state.totalPage = action.payload.totalPage || initialState.totalPage);
    },
    /**
     * !SET option get list
     *  */
    setOptionsGetList(state, action: PayloadAction<Partial<I_OptionsGetList>>) {
      const { search, page, limit, order, order_by } = action.payload;
      search !== undefined &&
        (state.optionsGetList.search = action.payload.search || '');
      limit !== undefined &&
        (state.optionsGetList.limit =
          action.payload.limit || initialState.optionsGetList.limit);
      page !== undefined &&
        (state.optionsGetList.page =
          action.payload.page || initialState.optionsGetList.page);
      order !== undefined &&
        (state.optionsGetList.order =
          action.payload.order || initialState.optionsGetList.order);
      order_by !== undefined &&
        (state.optionsGetList.order_by =
          action.payload.order_by || initialState.optionsGetList.order_by);
      page === 1 && (state.totalPage = initialState.totalPage);
    },
    /**
     * !RESET state
     *  */

    resetListProject(state) {
      state.listProject = [];
    },
    resetOptionGetList(state) {
      state.optionsGetList = initialState.optionsGetList;
    },
    reset(state) {
      Object.keys(state).forEach(item => {
        if (item !== 'listAllProject') state[item] = initialState[item];
      });
    },
  },
  extraReducers: {
    /**
     * !GET LIST PROJECT
     */
    [GET_LIST_PROJECT.TRIGGER]: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_LIST_PROJECT.SUCCESS]: (state, action: PayloadAction<I_Project[]>) => {
      state.listProject = [...state.listProject, ...action.payload];
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_LIST_PROJECT.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * !GET LIST ALL PROJECT
     */
    [GET_LIST_ALL_PROJECT.TRIGGER]: (state, action: PayloadAction<any>) => {},
    [GET_LIST_ALL_PROJECT.SUCCESS]: (
      state,
      action: PayloadAction<I_Project[]>,
    ) => {
      state.listAllProject = [...state.listAllProject, ...action.payload];
    },
    [GET_LIST_ALL_PROJECT.FAILURE]: state => {},
    /**
     * !CREATE PROJECT
     */
    [CREATE_PROJECT.TRIGGER]: (
      state,
      action: PayloadAction<I_PayloadCreateProject>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [CREATE_PROJECT.SUCCESS]: (state, action: PayloadAction<I_Project>) => {
      if (
        state.statusFilterProject === 'all' ||
        state.statusFilterProject === 'draft'
      )
        state.listProject = [...state.listProject, action.payload];
      state.listAllProject = [...state.listAllProject, action.payload];
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [CREATE_PROJECT.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * !UPDATE PROJECT
     */
    [UPDATE_PROJECT.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UPDATE_PROJECT.SUCCESS]: (state, action: PayloadAction<I_Project>) => {
      state.listProject.length &&
        (state.listProject = state.listProject.map(item =>
          item.id === action.payload.id ? action.payload : item,
        ));
      state.listAllProject.length &&
        (state.listAllProject = state.listAllProject.map(item =>
          item.id === action.payload.id ? action.payload : item,
        ));
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [UPDATE_PROJECT.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * !DELETE PROJECT
     */
    [DELETE_PROJECT.TRIGGER]: (
      state,
      action: PayloadAction<I_OptionDelete>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DELETE_PROJECT.SUCCESS]: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.listProject = state.listProject.filter(
        item => item.id !== action.payload.id,
      );
      state.listAllProject = state.listAllProject.filter(
        item => item.id !== action.payload.id,
      );
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [DELETE_PROJECT.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = projectManagementSlice;
