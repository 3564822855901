import { I_Hotspot } from './../types';
import { createGlobalState } from 'react-hooks-global-state';
import { TypeActionInteraction } from 'app/components/ActionInteraction/types';

const { useGlobalState: useHotspotStats } = createGlobalState<{
  hotspotEditing?: I_Hotspot;
  lastHotspotConfig?: I_Hotspot;
}>({
  hotspotEditing: undefined,
  lastHotspotConfig: undefined,
});

const { useGlobalState: useSettingHotspotGlobal } = createGlobalState<{
  setting?: {
    typeHotspotDirectionSelected?: 'chevron' | 'point';
    typeHotspotMediaSelected?: 'media-video' | 'media-image' | 'media-text';
  };
  polygonState: {
    idTemporary?: string;
    typeSelected?: TypeActionInteraction;
  };
}>({
  setting: {
    typeHotspotDirectionSelected: 'point',
    typeHotspotMediaSelected: 'media-image',
  },
  polygonState: {
    idTemporary: undefined,
    typeSelected: undefined,
  },
});

export { useHotspotStats, useSettingHotspotGlobal };
