export const PAYLOAD_GET_LIST_ALL = {
  page: 1,
  search: '',
  order: 'asc',
  order_by: 'updated_at',
  limit: 10000000,
};

export const LIST_OPTION_ORDER_BY_MEDIA = [
  { key: 'name', label: 'Name' },
  { key: 'created_at', label: 'Upload time' },
];

export const LIST_OPTION_ORDER_MEDIA = [
  { key: 'asc', label: 'Ascending' },
  { key: 'desc', label: 'Descending' },
];

export const DEFAULT_EXIF_IMAGE = {
  '0th': {
    ImageWidth: 8000,
    ImageLength: 4000,
    BitsPerSample: [8, 8, 8, 8],
    Compression: 32773,
    PhotometricInterpretation: 2,
    Make: 'RICOH',
    Model: 'RICOH THETA S',
    Orientation: 1,
    SamplesPerPixel: 4,
    XResolution: [3000000, 10000],
    YResolution: [3000000, 10000],
    PlanarConfiguration: 1,
    ResolutionUnit: 2,
    Software: 'Adobe Photoshop CC (Windows)',
    DateTime: '2017:09:01 19:06:28',
    ExifTag: 308,
  },
  Exif: {
    ExposureTime: [1, 4],
    FNumber: [2147483647, 477218588],
    ISOSpeedRatings: 200,
    DateTimeOriginal: '2017:06:02 17:22:14',
    DateTimeDigitized: '2017:06:02 17:22:14',
    ColorSpace: 1,
    PixelXDimension: 8000,
    PixelYDimension: 4000,
    InteroperabilityTag: 478,
  },
  GPS: {},
  Interop: {
    InteroperabilityIndex: 'R98',
  },
  '1st': {
    Compression: 6,
    XResolution: [72, 1],
    YResolution: [72, 1],
    ResolutionUnit: 2,
    JPEGInterchangeFormat: 590,
    JPEGInterchangeFormatLength: 3917,
  },
};

export const LIST_COUPON_LAST_DEAL = [
  { value: 1, label: '-80% (1 Months)', month: 1, discount: 80 },
  { value: 2, label: '-60% (3 Months)', month: 3, discount: 60 },
  { value: 3, label: '-40% (6 Months)', month: 6, discount: 40 },
] as const;

export const LIST_REASON_DEACTIVATE_ACCOUNT = [
  `It's too expensive or not valuable for me.`,
  `It's too complex or confusing for me.`,
  `Doesn't have the feature I need.`,
  `It has too many technical problems or slow solutions.`,
  `Poor customer support or community.`,
  `I don't want to host Virtual Tours in Cloud Platform`,
  `Doesn't display well on various devices or platforms.`,
  `I don't need virtual tours anymore or have different goals.`,
];
