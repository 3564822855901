// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import {
  Messaging,
  getMessaging,
  onMessage,
  getToken,
  deleteToken,
} from 'firebase/messaging';
import { useCallback, useMemo, useState } from 'react';
import { TYPE_COOKIE } from 'utils/constants';
import { setCookie } from 'utils/cookies';
import { useDispatch } from 'react-redux';
import { openNotificationWithIcon } from 'utils/helper';
import { GET_LIST_NOTIFICATION } from 'app/containers/App/slice';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_API_KEY_FIREBASE ||
    'AIzaSyC4Jh4CpPkdJAF24PdmAzb4tQhcv-XVv4w',
  authDomain:
    process.env.REACT_APP_AUTH_DOMAIN_FIREBASE ||
    'test-notification-217dd.firebaseapp.com',
  projectId:
    process.env.REACT_APP_PROJECT_ID_FIREBASE || 'test-notification-217dd',
  storageBucket:
    process.env.REACT_APP_STORAGE_BUCKET_FIREBASE ||
    'test-notification-217dd.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_MESSAGING_SENDER_ID_FIREBASE || '678362344599',
  appId:
    process.env.REACT_APP_APP_ID_FIREBASE ||
    '1:678362344599:web:8c951055a92c4fb97fcdd2',
  measurementId:
    process.env.REACT_APP_MEASUREMENT_ID_FIREBASE || 'G-3X408WMMET',
};

export const useFirebase = () => {
  const dispatch = useDispatch();
  const [deviceId, setDeviceId] = useState('');
  const [firebaseInstance, setFirebaseInstance] = useState<FirebaseApp>(null!);
  const [firebaseAnalytics, setFirebaseAnalytics] = useState<Analytics>(null!);
  const [firebaseMessaging, setFirebaseMessaging] = useState<Messaging>(null!);

  const onReceiveMessage = useCallback(
    (messaging: Messaging) => {
      onMessage(messaging, ({ data }) => {
        if (data && data.title && data.body) {
          openNotificationWithIcon(
            'info',
            'You have a new notification!',
            data.title,
            8,
            'bottom-left',
          );
          dispatch(GET_LIST_NOTIFICATION());
        }
      });
    },
    [dispatch],
  );

  const onGetToken = useCallback(
    async (messaging: Messaging) => {
      return new Promise(async (resolve, reject) => {
        try {
          const currentToken = await getToken(messaging, {
            vapidKey:
              process.env.REACT_APP_VAPI_KEY_FIREBASE ||
              'BPWP2nd773lfpnZc2EeyS8TP7kFqJud3BM4YUTDRX6TeKNDheQ0daxkvCgaT9jwUJBIx-y0H2EZhpA3RAU0SYzY',
          });
          setDeviceId(currentToken);
          setCookie(TYPE_COOKIE.DEVICE_ID, currentToken);
          await onReceiveMessage(messaging);
          resolve(true);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      });
    },
    [onReceiveMessage],
  );

  const onDeleteToken = useCallback(async () => {
    // Delete registration token.
    try {
      const result = await deleteToken(firebaseMessaging);
      console.log('Token deleted.', result);
    } catch (err) {
      console.log('Unable to delete token. ', err);
    }
  }, [firebaseMessaging]);

  const onRequestPermission = useCallback(
    (messaging: Messaging) => {
      return new Promise(async resolve => {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          // TODO(developer): Retrieve a registration token for use with FCM.
          // In many cases once an app has been granted notification permission,
          // it should update its UI reflecting this.
          await onGetToken(messaging);
        } else {
          console.log('Unable to get permission to notify.');
        }
        resolve(true);
      });
    },
    [onGetToken],
  );

  const onInitFirebase = useCallback(() => {
    return new Promise(async resolve => {
      try {
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        const messaging = getMessaging(app);
        setFirebaseInstance(app);
        setFirebaseAnalytics(analytics);
        setFirebaseMessaging(messaging);
        await onRequestPermission(messaging);
      } catch (err) {
        console.log(err);
      } finally {
        resolve(true);
      }
    });
  }, [onRequestPermission]);

  const onResetFirebase = useCallback(() => {
    setDeviceId('');
  }, []);

  return useMemo(
    () => ({
      deviceId,
      firebaseInstance,
      firebaseAnalytics,
      firebaseMessaging,
      onInitFirebase,
      onRequestPermission,
      onDeleteToken,
      onResetFirebase,
    }),
    [
      deviceId,
      firebaseAnalytics,
      firebaseInstance,
      firebaseMessaging,
      onDeleteToken,
      onInitFirebase,
      onRequestPermission,
      onResetFirebase,
    ],
  );
};
