/**
 *
 * Asynchronously loads the component for AnalyticUsers
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnalyticUsers = lazyLoad(
  () => import('./index'),
  module => module.AnalyticUsers,
);

export const DialogCopyConfig = lazyLoad(
  () => import('./components/DialogCopyConfig'),
  module => module.DialogCopyConfig,
);

export const ListUser = lazyLoad(
  () => import('./ListUser'),
  module => module.ListUser,
);

export const DialogTableList = lazyLoad(
  () => import('./ListUser/DialogTableList'),
  module => module.DialogTableList,
);

export const DetailUser = lazyLoad(
  () => import('./DetailUser'),
  module => module.DetailUser,
);

export const DialogSceneList = lazyLoad(
  () => import('./DetailUser/DialogSceneList'),
  module => module.DialogSceneList,
);
