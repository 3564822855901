/**
 *
 * styled OverlayThumb
 *
 */
import styled from 'styled-components/macro';
import { FlexColCenter } from 'styles/global-styles';

export const OverlayThumbWrapper = styled.div`
  background: rgb(0 0 0 / 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  ${FlexColCenter};
  /* backdrop-filter: blur(2px); */
  pointer-events: none;
  z-index: 10;
  .overlay {
    &-icon {
      font-size: 2rem;
      display: flex;
    }
    &-label {
      font-size: 0.75rem;
      padding: 0 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0.5rem;
      max-width: 100%;
    }
  }
`;
