import Pusher from 'pusher-js';

const _window = window as any;

Pusher.logToConsole = true;

// var channel = pusher.subscribe('user-6002698641f07bd3a7bafd51');
// channel.bind('media-thumb', function (data) {
//   alert(JSON.stringify(data));
//   console.log(data);
// });
// channel.bind('media-preview', function (data) {
//   alert(JSON.stringify(data));
//   console.log(data);
// });

export function initPusher() {
  _window.pusher = new Pusher(
    process.env.REACT_APP_PUSHER_KEY || '0e13776651afbfd28cdf',
    {
      cluster: 'ap1',
    },
  );

  _window.pusher.connection.bind('connected', function () {
    console.log('pusher connected');
  });

  // _window.pusher.on('event', function (data) {
  //   console.log('pusher event: ', data);
  // });

  _window.pusher.connection.bind('disconnect', function () {
    console.log('pusher destroy');
    // _window.pusher.open();
  });

  _window.pusher.connection.bind('error', function (err) {
    console.log(err);
  });
}

export function destroyPusher() {
  _window.pusher && _window.pusher.disconnect();
}
