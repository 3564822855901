import { createRoutine } from 'redux-saga-routines';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  I_Card,
  I_Charge,
  I_Invoice,
  I_PaylaodChangePassword,
  I_PayloadEditProfile,
} from './types';
import { I_OptionDelete } from 'types';

// The initial state of the Profile container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  loadingAvt: false,
  loadingCover: false,
  loadingLogo: false,
  cards: [],
  successAddCard: false,
  invoices: [],
  invoicesFail: [],
};

export const PUT_LOGO = createRoutine('profile/putLogo');
export const PUT_AVATAR = createRoutine('profile/putAvatar');
export const PUT_COVER = createRoutine('profile/putCover');
export const PUT_PROFILE = createRoutine('profile/putProfile');
export const CHANGE_PASSWORD = createRoutine('profile/changePassword');

export const GET_CARDS = createRoutine('profile/getCards');
export const ADD_CARD = createRoutine('profile/addCard');
export const SET_DEFAULT_CARD = createRoutine('profile/setDefaultCard');
export const DELETE_CARD = createRoutine('profile/deleteCard');

export const GET_INVOICES = createRoutine('profile/getInvoices');
export const RETRY_INVOICE = createRoutine('profile/retryInvoice');

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    /**
     * ! PUT AVATAR
     */
    [PUT_AVATAR.TRIGGER]: (state, action: PayloadAction<{ avatar: File }>) => {
      state.loadingAvt = true;
    },
    [PUT_AVATAR.SUCCESS]: state => {
      state.loadingAvt = false;
    },
    [PUT_AVATAR.FAILURE]: state => {
      state.loadingAvt = false;
    },
    /**
     * ! PUT LOGO
     */
    [PUT_LOGO.TRIGGER]: (state, action: PayloadAction<{ logo: File }>) => {
      state.loadingLogo = true;
    },
    [PUT_LOGO.SUCCESS]: state => {
      state.loadingLogo = false;
    },
    [PUT_LOGO.FAILURE]: state => {
      state.loadingLogo = false;
    },
    /**
     * ! PUT COVER
     */
    [PUT_COVER.TRIGGER]: (state, action: PayloadAction<{ cover: File }>) => {
      state.loadingCover = true;
    },
    [PUT_COVER.SUCCESS]: state => {
      state.loadingCover = false;
    },
    [PUT_COVER.FAILURE]: state => {
      state.loadingCover = false;
    },
    /**
     * ! PUT PROFILE
     */
    [PUT_PROFILE.TRIGGER]: (
      state,
      action: PayloadAction<I_PayloadEditProfile>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [PUT_PROFILE.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [PUT_PROFILE.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! CHANGE PASSWORD
     */
    [CHANGE_PASSWORD.TRIGGER]: (
      state,
      action: PayloadAction<I_PaylaodChangePassword>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [CHANGE_PASSWORD.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [CHANGE_PASSWORD.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! GET CARDS
     */
    [GET_CARDS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_CARDS.SUCCESS]: (state, action: PayloadAction<I_Card[]>) => {
      state.cards = action.payload || [];
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_CARDS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! ADD CARD
     */
    [ADD_CARD.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ADD_CARD.SUCCESS]: (state, action: PayloadAction<I_Card>) => {
      state.cards = [...state.cards, ...[action.payload]];
      state.loading = false;
      state.success = true;
      if (!state.successAddCard) state.successAddCard = true;
      state.error = false;
    },
    [ADD_CARD.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! SET DEFAULT CARDS
     */
    [SET_DEFAULT_CARD.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [SET_DEFAULT_CARD.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [SET_DEFAULT_CARD.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! DETELE CARD
     */
    [DELETE_CARD.TRIGGER]: (state, action: PayloadAction<I_OptionDelete>) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DELETE_CARD.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [DELETE_CARD.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    /**
     * ! GET INVOICES
     */
    [GET_INVOICES.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_INVOICES.SUCCESS]: (
      state,
      action: PayloadAction<{ invoices: I_Invoice[]; charges: I_Charge[] }>,
    ) => {
      state.invoices = [
        ...action.payload.charges?.filter(item => !item.invoice),
        ...action.payload.invoices.filter(
          item => item.status === 'paid' && item.total !== 0,
        ),
      ];
      state.invoicesFail =
        action.payload.invoices.filter(
          item => item.status !== 'paid' && item.total !== 0,
        ) || [];
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_INVOICES.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    /**
     * ! RETRY INVOICE
     */
    [RETRY_INVOICE.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [RETRY_INVOICE.SUCCESS]: (state, action: PayloadAction<I_Charge>) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [RETRY_INVOICE.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;
