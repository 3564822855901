/**
 *
 * Loadable App components
 *
 */

import { lazyLoad } from 'utils/loadable';

export const TrialEndFlow = lazyLoad(
  () => import('./'),
  module => module.TrialEndFlow,
);

export const DialogPackages = lazyLoad(
  () => import('./DialogPackages'),
  module => module.DialogPackages,
);

export const DialogLastDeal = lazyLoad(
  () => import('./DialogLastDeal'),
  module => module.DialogLastDeal,
);

export const DialogSwitchFreeTier = lazyLoad(
  () => import('./DialogSwitchFreeTier'),
  module => module.DialogSwitchFreeTier,
);

export const DialogConfirmationFreeTier = lazyLoad(
  () => import('./DialogConfirmationFreeTier'),
  module => module.DialogConfirmationFreeTier,
);

export const DialogConfirmationDeactiveAccount = lazyLoad(
  () => import('./DialogConfirmationDeactiveAccount'),
  module => module.DialogConfirmationDeactiveAccount,
);
