import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { createRoutine } from 'redux-saga-routines';

// The initial state of the Account container
export const initialState: ContainerState = {
  verify: {
    loading: false,
    success: false,
    error: false,
  },
  forgot_pw: {
    loading: false,
    success: false,
    error: false,
  },
  register: {
    email: '',
    password: '',
  },
  loading: false,
  success: false,
  error: false,
};

export const LOGIN = createRoutine('Account/login');
export const LOGIN_SOCIAL = createRoutine('Account/loginSocial');
export const VERIFY = createRoutine('Account/verify');
export const REGISTER = createRoutine('Account/register');
export const REGISTER_FB = createRoutine('Account/registerFacebook');
export const REGISTER_GG = createRoutine('Account/registerGoogle');
export const RESET_PASSWORD = createRoutine('Account/reset-password');
export const FORGOT_PASSWORD = createRoutine('Account/forgot-password');

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) {},
  },
  extraReducers: {
    // NOTE: Login

    [LOGIN.TRIGGER]: (
      state,
      action: PayloadAction<{ email: string; password: string }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [LOGIN.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.register = initialState.register;
    },
    [LOGIN.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
    // NOTE: Login Social

    [LOGIN_SOCIAL.TRIGGER]: (
      state,
      action: PayloadAction<{
        provider_id: string;
        provider_type: 'google' | 'facebook';
      }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [LOGIN_SOCIAL.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [LOGIN_SOCIAL.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    // NOTE: Register

    [REGISTER.TRIGGER]: (
      state,
      action: PayloadAction<{
        email: string;
        name?: string;
        phone?: number;
        password: string;
        confirm_password: string;
      }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [REGISTER.SUCCESS]: (
      state,
      action: PayloadAction<{
        email: string;
        password: string;
      }>,
    ) => {
      state.register = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [REGISTER.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    // NOTE: Register Facebook Social

    [REGISTER_FB.TRIGGER]: (
      state,
      action: PayloadAction<{
        access_token: string;
        email: string;
      }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [REGISTER_FB.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [REGISTER_FB.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    // NOTE: Register Google Social

    [REGISTER_GG.TRIGGER]: (
      state,
      action: PayloadAction<{
        access_token: string;
        email: string;
      }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [REGISTER_GG.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [REGISTER_GG.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    // NOTE: Verify

    [VERIFY.TRIGGER]: (state, action: PayloadAction<{ code?: string }>) => {
      state.verify.loading = true;
      state.verify.success = false;
      state.verify.error = false;
    },
    [VERIFY.SUCCESS]: state => {
      state.verify.loading = false;
      state.verify.success = true;
      state.verify.error = false;
    },
    [VERIFY.FAILURE]: state => {
      state.verify.success = false;
      state.verify.loading = false;
      state.verify.error = true;
    },
    // NOTE: Forgot Password

    [FORGOT_PASSWORD.TRIGGER]: (
      state,
      action: PayloadAction<{ email: string }>,
    ) => {
      state.forgot_pw.loading = true;
      state.forgot_pw.success = false;
      state.forgot_pw.error = false;
    },
    [FORGOT_PASSWORD.SUCCESS]: state => {
      state.forgot_pw.loading = false;
      state.forgot_pw.success = true;
      state.forgot_pw.error = false;
    },
    [FORGOT_PASSWORD.FAILURE]: state => {
      state.forgot_pw.success = false;
      state.forgot_pw.loading = false;
      state.forgot_pw.error = true;
    },
    // NOTE: Reset password

    [RESET_PASSWORD.TRIGGER]: (
      state,
      action: PayloadAction<{
        code: string;
        password: string;
        confirm_password: string;
      }>,
    ) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [RESET_PASSWORD.SUCCESS]: state => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [RESET_PASSWORD.FAILURE]: state => {
      state.success = false;
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = accountSlice;
