import { BaseXHR } from 'utils/axios';

export const uploadMedia = payload => BaseXHR.$post('media/upload', payload);
// return PostWithFormData('localhost:5000/', body)

// export const callApiGetListMedia = (page=1, limit=10, search='', order_by='created_at', order='desc', mimetype='image/jpeg') => {
export const callApiGetListMedia = params => BaseXHR.$get('media/gets', params);

export const getListMediaBuiltInApi = params =>
  BaseXHR.$get('auth/list-library', params);

export const updateMediaApi = params => BaseXHR.$put('media/update', params);

export const deletesOrMovesMediaApi = params =>
  BaseXHR.$put('media/deletesOrMovesMedia', params);

export const deleteMediaApi = id => BaseXHR.$delete('media/delete', { id });

export const createAlbumApi = params =>
  BaseXHR.$post('media/createFolder', params);
