import { BaseXHRPimCore } from 'utils/axios';

export const getListProvinceApi = (payload = {}) =>
  BaseXHRPimCore.$get('vi/province/listing', payload);

export const getListPlaceApi = (payload = {}) =>
  BaseXHRPimCore.$get('vi/place-category/listing', payload);

export const getListLocationApi = (payload = {}) =>
  BaseXHRPimCore.$get('vi/location/listing', payload);

export const getListTranslationApi = (
  payload = {
    channel: 'messages',
  },
) => BaseXHRPimCore.$get('vi/translator/get', payload);
