import { actions as actionsApp } from 'app/containers/App/slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  callApiChangePassword,
  callApiPutAvatar,
  callApiPutCover,
  callApiPutLogo,
  callApiPutProfile,
} from 'app/services/profileApi';
import {
  ADD_CARD,
  CHANGE_PASSWORD,
  DELETE_CARD,
  GET_CARDS,
  GET_INVOICES,
  PUT_AVATAR,
  PUT_COVER,
  PUT_LOGO,
  PUT_PROFILE,
  RETRY_INVOICE,
  SET_DEFAULT_CARD,
} from './slice';
import {
  createCardApi,
  getListCardApi,
  setDefaultCardApi,
} from 'app/services/cardApi';
import { deleteCardApi } from '../../services/cardApi';
import { getInvoicesApi, retryInvoiceApi } from 'app/services/invoiceApi';

/**
 * @param PROFILE
 */
function* putAvatar({ payload }) {
  try {
    const response = yield call(callApiPutAvatar, payload);
    yield put(PUT_AVATAR.success());
    yield put(actionsApp.setInfoUser(response.data.data));
  } catch (err) {
    yield put(PUT_AVATAR.failure());
  }
}

function* putLogo({ payload }) {
  try {
    const response = yield call(callApiPutLogo, payload);
    yield put(actionsApp.setInfoUser(response.data.data));
    yield put(PUT_LOGO.success());
  } catch (err) {
    yield put(PUT_LOGO.failure());
  }
}

function* putCover({ payload }) {
  try {
    const response = yield call(callApiPutCover, payload);
    yield put(actionsApp.setInfoUser(response.data.data));
    yield put(PUT_COVER.success());
  } catch (err) {
    yield put(PUT_COVER.failure());
  }
}

function* putProfile({ payload }) {
  try {
    const response = yield call(callApiPutProfile, payload);
    yield put(PUT_PROFILE.success());
    yield put(actionsApp.setInfoUser(response.data.data));
  } catch (err) {
    yield put(PUT_PROFILE.failure());
  }
}

function* changePassword({ payload }) {
  try {
    yield call(callApiChangePassword, payload);
    yield put(CHANGE_PASSWORD.success());
  } catch (err) {
    yield put(CHANGE_PASSWORD.failure());
  }
}

/**
 * @param CARDS
 */

function* getCards() {
  try {
    const { data } = yield call(getListCardApi);
    yield put(GET_CARDS.success(data?.data || []));
  } catch (err) {
    yield put(GET_CARDS.failure());
  }
}

function* addCard({ payload }) {
  try {
    const { data } = yield call(createCardApi, payload);
    yield put(ADD_CARD.success(data.data));
  } catch (err) {
    yield put(ADD_CARD.failure());
  }
}

function* setDefaultCard({ payload }) {
  try {
    yield call(setDefaultCardApi, payload);
    yield put(SET_DEFAULT_CARD.success());
    yield put(GET_CARDS());
  } catch (err) {
    yield put(SET_DEFAULT_CARD.failure());
  }
}

function* deleteCard({ payload }) {
  try {
    yield call(deleteCardApi, payload);
    yield put(DELETE_CARD.success());
    yield put(GET_CARDS());
  } catch (err) {
    yield put(DELETE_CARD.failure());
  }
}

/**
 * @param INVOICES
 */

function* getInvoices({ payload }) {
  try {
    const { data } = yield call(getInvoicesApi, payload);
    yield put(
      GET_INVOICES.success({
        charges: data?.charges || [],
        invoices: data?.invoices || [],
      }),
    );
  } catch (err) {
    yield put(GET_INVOICES.failure());
  }
}

function* retryInvoice({ payload }) {
  try {
    const { data } = yield call(retryInvoiceApi, payload);
    yield put(RETRY_INVOICE.success(data.data));
  } catch (err) {
    yield put(RETRY_INVOICE.failure());
  }
}

export function* profileSaga() {
  yield takeLatest(PUT_AVATAR.TRIGGER, putAvatar);
  yield takeLatest(PUT_COVER.TRIGGER, putCover);
  yield takeLatest(PUT_LOGO.TRIGGER, putLogo);
  yield takeLatest(PUT_PROFILE.TRIGGER, putProfile);
  yield takeLatest(CHANGE_PASSWORD.TRIGGER, changePassword);
  // ------
  yield takeLatest(GET_CARDS.TRIGGER, getCards);
  yield takeLatest(ADD_CARD.TRIGGER, addCard);
  yield takeLatest(SET_DEFAULT_CARD.TRIGGER, setDefaultCard);
  yield takeLatest(DELETE_CARD.TRIGGER, deleteCard);
  // ------
  yield takeLatest(GET_INVOICES.TRIGGER, getInvoices);
  yield takeLatest(RETRY_INVOICE.TRIGGER, retryInvoice);
}
