import { BaseXHR } from 'utils/axios';
import { I_OptionDelete, I_OptionsGetList } from 'types';
import { I_PayloadUpdatePortfolio } from 'app/containers/Profile/Portfolio/types';

export const getPortfolioApi = (id: string) =>
  BaseXHR.$get('account/portfolio/get', { id });

export const updatePortfolioApi = (payload: I_PayloadUpdatePortfolio) =>
  BaseXHR.$put('account/portfolio/update', payload);

export const getCollectionsPortfolioApi = (payload: I_OptionsGetList) =>
  BaseXHR.$get('account/collectionPortfolio/gets', payload);

export const getCollectionPortfolioApi = (id: string) =>
  BaseXHR.$get('account/collectionPortfolio/get', { id });

export const createCollectionPortfolioApi = (payload: FormData) =>
  BaseXHR.$post('account/collectionPortfolio/create', payload);

export const updateCollectionPortfolioApi = (payload: FormData) =>
  BaseXHR.$put('account/collectionPortfolio/update', payload);

export const deleteCollectionPortfolioApi = (payload: I_OptionDelete) =>
  BaseXHR.$delete('account/collectionPortfolio/delete', payload);
