import { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectInfoUser } from 'app/containers/App/selectors';

export const useStripe = () => {
  const infoUser = useSelector(selectInfoUser);
  const [loading, setLoading] = useState(false);

  const onConfigStripe = useCallback(async () => {
    if ((window as any).StripeCheckout)
      (window as any).StripeCheckoutHandler = (window as any).StripeCheckout.configure(
        {
          key: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
          image:
            'https://stripe.com/img/documentation/checkout/marketplace.png',
          email: infoUser?.email || '',
          locale: 'auto',
        },
      );
  }, [infoUser]);

  const onOpenFormAddCard = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (
        (window as any).StripeCheckout &&
        (window as any).StripeCheckoutHandler
      ) {
        (window as any).StripeCheckoutHandler.open({
          name: 'Panoee',
          description: 'Add Card',
          label: 'Add Card',
          token: async token => {
            try {
              setLoading(true);
              resolve(token);
            } catch (err) {
              console.log(err);
              reject(err);
            } finally {
              setLoading(false);
            }
          },
        });
      }
    });
  }, []);

  return useMemo(
    () => ({
      loading,
      onConfigStripe,
      onOpenFormAddCard,
    }),
    [loading, onConfigStripe, onOpenFormAddCard],
  );
};
