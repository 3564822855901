import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.app || initialState;

const notification = (state: RootState) => state.notifications || initialState;

export const selectNotifications = createSelector(
  [notification],
  state => state,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsShowDrawerGlobalConfig = createSelector(
  [selectDomain],
  state => state.isShowDrawerGlobalConfig,
);

export const selectInfoUser = createSelector(
  [selectDomain],
  state => state.infoUser,
);

export const selectMyStorageUsed = createSelector(
  [selectInfoUser],
  state => state?.size || 0,
);

export const selectRefreshTokenGoogle = createSelector(
  [selectInfoUser],
  state => state?.refresh_token_google || undefined,
);

export const selectFreeProject = createSelector([selectInfoUser], infoUser =>
  infoUser?.free_project ? infoUser?.free_project?.split(',') : [],
);

export const selectFreeProjectByMonth = createSelector(
  [selectInfoUser],
  infoUser => {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const monthCurrently = ye + '-' + mo;

    return infoUser?.subCheckProject
      ? infoUser?.subCheckProject[monthCurrently] || []
      : [];
  },
);

export const selectFeatureRequestVoted = createSelector(
  [selectDomain],
  state => state.featureRequestVoted || [],
);

export const selectSubscriptionInfo = createSelector(
  [selectDomain],
  state => state.subscriptionInfo || undefined,
);

export const selectIsUnlimitedUser = createSelector(
  [selectInfoUser],
  state => !!state?.unlimited,
);

export const selectIsInTrial = createSelector(
  [selectInfoUser, selectSubscriptionInfo],
  (infoUser, subscriptionInfo) =>
    (subscriptionInfo?.status === 'active' &&
      subscriptionInfo?.statusPackage === 'trialing') ||
    (!infoUser?.free_trial_end && !!infoUser?.free_trial),
);

export const selectIsProOrLegacyPlan = createSelector(
  [selectIsUnlimitedUser, selectSubscriptionInfo],
  (isUnlimited, state) => isUnlimited || state?.status === 'active',
);

export const selectIsProPlan = createSelector(
  [selectIsUnlimitedUser, selectSubscriptionInfo, selectIsInTrial],
  (isUnlimited, state, isTrial) =>
    isUnlimited ||
    isTrial ||
    (state?.status === 'active' &&
      state.id !== '1' &&
      (state?.statusPackage === 'active' ||
        state?.statusPackage === 'trialing' ||
        state?.statusPackage === 'canceled')),
);

export const selectIsLogged = createSelector(
  [selectDomain],
  state => state.isLogged,
);

/**
 * ! AWS
 */

export const selectBucketAWS = createSelector(
  [selectDomain],
  state => state.aws.bucket,
);

export const selectRegionAWS = createSelector(
  [selectDomain],
  state => state.aws.region,
);

export const selectAccessKeyIdAWS = createSelector(
  [selectDomain],
  state => state.aws.accessKeyId,
);

export const selectSecretAccessKeyAWS = createSelector(
  [selectDomain],
  state => state.aws.secretAccessKey,
);

export const selectSessionTokenAWS = createSelector(
  [selectDomain],
  state => state.aws.sessionToken,
);

/**
 * ! MEDIA
 */

export const selectTotalPageMedia = createSelector(
  [selectDomain],
  state => state.media.totalPageMedia,
);

export const selectTotalDocMedia = createSelector(
  [selectDomain],
  state => state.media.totalDocMedia,
);

export const selectOptionsGetMedia = createSelector(
  [selectDomain],
  state => state.media.optionsGetListMedia,
);

export const selectOptionsGetAlbum = createSelector(
  [selectDomain],
  state => state.media.optionsGetListAlbum,
);

export const selectAlbumSelected = createSelector(
  [selectDomain],
  state => state.media.albumSelected,
);

export const selectListAlbum = createSelector(
  [selectDomain],
  state => state.media.listAlbum,
);

export const selectListMediaBuiltInImage = createSelector(
  [selectDomain],
  state => state.media.listMediaBuiltInHotspotImage,
);

export const selectListMediaBuiltInMarker = createSelector(
  [selectDomain],
  state => state.media.listMediaBuiltInHotspotMarker,
);

export const selectListMediaBuiltInLottie = createSelector(
  [selectDomain],
  state => state.media.listMediaBuiltInHotspotLottie,
);

export const selectListMedia = createSelector(
  [selectDomain],
  state => state.media.listMedia,
);

export const selectListMediaUploading = createSelector(
  [selectDomain],
  state => state.media.listMediaUploading,
);

export const selectIsLoadingDeletesOrMovesMedia = createSelector(
  [selectDomain],
  state => state.media.loadingDeletesOrMoves !== 0,
);

export const selectIsLoadingMedia = createSelector(
  [selectDomain],
  state => state.media.loading !== 0,
);

export const selectIsErrorMedia = createSelector(
  [selectDomain],
  state => state.media.error,
);

export const selectIsSuccessMedia = createSelector(
  [selectDomain],
  state => state.media.success,
);

export const selectIsSuccessDeletesOrMovesMedia = createSelector(
  [selectDomain],
  state => state.media.successDeletesOrMoves,
);

export const selectListCategory = createSelector(
  [selectDomain],
  state => state.listCategory,
);

export const selectListNotification = createSelector(
  [selectDomain, selectInfoUser],
  (state, user) =>
    state.listNotification.map(item => ({
      ...item,
      read: (user?.list_notification_read || []).indexOf(item.id) > -1,
    })),
);

export const selectListLocation = createSelector(
  [selectDomain],
  state => state.listLocation,
);

export const selectListPlaceCategory = createSelector(
  [selectDomain],
  state => state.listPlaceCategory,
);

export const selectListProvince = createSelector(
  [selectDomain],
  state => state.listProvince,
);

export const selectListTranslation = createSelector(
  [selectDomain],
  state => state.listTranslation,
);
