/**
 *
 * styled Input
 *
 */
import styled, { css } from 'styled-components/macro';
import { Flex, FlexRowEnd } from 'styles/global-styles';

export const InputWrapper = styled.div<{
  width: number | 'auto';
  size: 'sm' | 'md' | 'lg';
  theme: 'dark' | 'light';
  error: boolean;
}>`
  position: relative;
  .errors {
    color: var(--color-red);
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .ant-input {
    border: 1px solid rgba(255, 255, 255, 0.2);
    outline: none !important;
    box-shadow: none !important;
    padding: 0.25rem 0.5rem;
    width: ${props =>
      props.width && props.width !== 'auto' ? `${props.width}px` : '100%'};
    color: ${props =>
      props.theme === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)'};
    font-size: ${props =>
      props.size === 'sm' ? '14px' : props.size === 'lg' ? '16px' : '14px'};
    ${props =>
      props.size === 'sm' &&
      css`
        font-weight: 400;
      `};
    background-color: ${props =>
      props.theme === 'dark' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.25)'};
    &::placeholder {
      opacity: 0.5;
      color: ${props =>
        props.theme === 'dark'
          ? 'rgba(255, 255, 255, 1)'
          : 'rgba(255, 255, 255, 1)'};
      font-size: ${props =>
        props.size === 'sm' ? '14px' : props.size === 'lg' ? '16px' : '15px'};
    }
    &-prefix {
      margin-right: 8px;
    }
    &-suffix {
      margin-left: 8px;
    }
    &-prefix,
    &-suffix {
      color: var(--color-smoke);
      font-size: 11px;
    }
    &-affix-wrapper,
    &-affix-wrapper-sm {
      padding: 2px 0.5rem;
      border-radius: 4px;
      box-shadow: none !important;
      background: transparent;
      color: #fff;
    }
    &-group {
      background-color: #ffffff;
      border-radius: 6px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.65),
        0 4px 4px 0 rgba(0, 0, 0, 0.22);
    }
    &-affix-wrapper {
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }
      ${props =>
        props.theme === 'dark' &&
        css`
          background-color: unset;
        `}
    }
    &:hover {
      border-color: rgb(255 255 255 / 0.5);
    }
    &:focus {
      ${props =>
        props.theme === 'dark' &&
        css`
          border-color: #ffffff;
          background-color: rgb(0 0 0 / 0.5);
        `}
    }
    &:active {
    }
    &[disabled] {
      background-color: ${props =>
        props.theme === 'dark' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.25)'};
      color: ${props =>
        props.theme === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)'};
    }
  }
  textarea.ant-input {
    resize: none;
    padding: 0.25rem 0.5rem;
    /* background: transparent; */
    border-radius: 4px;
    color: inherit;
    max-height: 250px !important;
    user-select: all;
  }
  .ant-input-textarea-clear-icon {
    color: #fff;
  }
  ${props =>
    props.error &&
    css`
      .ant-input,
      .ant-input-affix-wrapper {
        border-bottom-style: solid !important;
        border-color: var(--color-red) !important;
        &:hover,
        &:focus,
        &:active {
          border-bottom-style: solid !important;
          border-color: var(--color-red) !important;
        }
      }
    `}
`;

export const ViewControlInputWrapper = styled.div<{ type: string }>`
  ${props =>
    props.type === 'col'
      ? css`
          .input__wrapper {
            /* width: 80%;
            margin: auto; */
          }
        `
      : props.type === 'row'
      ? css`
          .input__group {
            ${Flex};
            align-items: flex-end;
          }
          .input__wrapper {
            width: 100%;
          }
          .input__actions {
            ${FlexRowEnd};
            margin-left: 5px;
          }
        `
      : css`
          ${Flex}
          width: 100%;
          .input__group {
            ${Flex};
          }
          .input__actions {
            ${FlexRowEnd};
            align-items: flex-end;
          }
        `}
  width: calc(50% - .25rem);
  margin-bottom: 0.5rem;
  padding: 0 0.5rem 0.5rem;
  background: var(--color-dark-2);
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  border-radius: 8px;
  &[type='default-view'] {
    width: 100%;
  }
  &:not(:nth-child(2n)) {
    margin-right: 0.25rem;
  }

  .input {
    &__col {
      border: none;
      margin-bottom: 0.25rem;
      :focus,
      :hover {
        border: none;
      }
    }
    &__title {
      font-size: 11px;
      color: rgb(255 255 255 / 0.5);
      margin-top: -8px;
    }
    &__default-view-wrapper {
      margin-right: 0.25rem;
    }
  }
  .ant-input {
    color: #fff;
    background: none;
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 0.25);
    text-align: left;
    padding: 0;
    border-radius: 0;
    font-size: 1rem;
    &:hover {
      border-color: rgb(255 255 255 / 0.5);
    }
    &:focus {
      border-color: #fff;
      background-color: transparent;
    }
  }
`;
