/**
 *
 * Asynchronously loads the component for Editors
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Editors = lazyLoad(
  () => import('./index'),
  module => module.Editors,
);
