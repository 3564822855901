import { call, select, put, takeLatest, all } from 'redux-saga/effects';
import {
  GET_LIST_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  actions,
  GET_LIST_ALL_PROJECT,
} from './slice';

import {
  callApiGetListProjects,
  callApiCreateNewProject,
  callApiDeleteProject,
  updateProjectApi,
} from 'app/services/projectAPI';
import { actions as actionsEditor } from '../../Editors/slice';
import { actions as actionsApp } from '../../App/slice';
import { selectDetailProject } from '../../Editors/selectors';
import { history } from 'utils/history';
import { reindexURLApi } from 'app/services/reindexSeoApi';
import { selectInfoUser } from 'app/containers/App/selectors';
import { updatePortfolioApi } from 'app/services/portfolioApi';

function* getListProject({ payload }) {
  try {
    const response = yield call(callApiGetListProjects, payload);
    yield put(
      actions.setOptionPagination({ totalPage: response.data.data.totalPages }),
    );
    yield put(GET_LIST_PROJECT.success(response.data.data.docs));
  } catch (err) {
    yield put(GET_LIST_PROJECT.failure());
  }
}

function* getListAllProject({ payload }) {
  try {
    const response = yield call(callApiGetListProjects, payload);
    yield put(GET_LIST_ALL_PROJECT.success(response.data.data.docs));
  } catch (err) {
    yield put(GET_LIST_ALL_PROJECT.failure());
  }
}

function* createNewProject({ payload }) {
  try {
    const response = yield call(callApiCreateNewProject, payload);
    const { data } = response.data;
    if (data) {
      yield put(CREATE_PROJECT.success(data));
      yield history.push(`/editor/${data.id}`);
    }
  } catch (err) {
    yield put(CREATE_PROJECT.failure());
  }
}

function* updateProject({ payload }) {
  try {
    /**
     * @context update project used both dashboard and editor page.
     * @purpose only update listProject or projectDetail if they're existed
     *  */

    const infoUser = yield select(selectInfoUser);
    const projectSelected = yield select(selectDetailProject);
    try {
      if (!!payload.publish || (!!payload.slug && !!projectSelected?.publish)) {
        if (
          !projectSelected.isIndexed ||
          (!!payload.slug && !!projectSelected?.publish)
        )
          yield call(reindexURLApi, {
            url: `${process.env.REACT_APP_PUBLIC_DOMAIN}${process.env.REACT_APP_PUBLIC_PATH_PUBLIC}/${projectSelected.slug}`,
          });
        if (!infoUser?.portfolio?.isIndexed) {
          yield call(reindexURLApi, {
            url: `${process.env.REACT_APP_PORTFOLIO_DOMAIN}/${infoUser?.portfolio?.slug}`,
          });
          yield call(updatePortfolioApi, {
            id: infoUser?.portfolio.id,
            isIndexed: true,
          });
          yield put(
            actionsApp.setInfoUser({
              ...infoUser,
              portfolio: { ...infoUser.portfolio, isIndexed: true },
            }),
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
    const response = yield call(updateProjectApi, {
      ...payload,
      ...(payload.publish ? { isIndexed: payload.publish } : {}),
    });
    yield put(UPDATE_PROJECT.success(response?.data?.data));
    projectSelected &&
      (yield put(actionsEditor.updateProjectDetail(response?.data?.data)));
  } catch (err) {
    yield put(UPDATE_PROJECT.failure());
  }
}

function* deleteProject({ payload }) {
  try {
    const response = yield call(callApiDeleteProject, payload);
    response.data.msg && (yield put(DELETE_PROJECT.success(payload)));
  } catch (err) {
    yield put(DELETE_PROJECT.failure());
  }
}

export function* projectManagementSaga() {
  yield all([
    yield takeLatest(GET_LIST_PROJECT.TRIGGER, getListProject),
    yield takeLatest(GET_LIST_ALL_PROJECT.TRIGGER, getListAllProject),
    yield takeLatest(CREATE_PROJECT.TRIGGER, createNewProject),
    yield takeLatest(UPDATE_PROJECT.TRIGGER, updateProject),
    yield takeLatest(DELETE_PROJECT.TRIGGER, deleteProject),
  ]);
}
